import React, { useState } from "react";
import { Box, Typography, Link } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

import Calendar from "../../images/calendar2.inline.svg";


const btn = {
  position: "relative",
  fontFamily: ["Poppins", "Helvetica", "Arial"].join(","),
  fontWeight: 600,
  fontSize: 12,
  lineHeight: 2,
  mr: 2.2,
  display: "inline-block",
  transition: ".3s ease-in-out",
  textDecoration: "underline",
  textDecorationThickness: "0.5px",
  "&:hover": {
    color: "#1CE7C2",
  },
};

const eventInfo = {
  width: { xs: "100%", md: "55%" },
  px: { xs: 2, md: 4 },
  pt: 4.8,
  pb: { xs: 3, md: 4 },
  position: "relative",
};



const eventBox = {
  position: "absolute",
  left: "-20px",
  top: "0",
  transform: "translateY(-50%)",
  zIndex: 2,
  "&:before": {
    content: "''",
    background: "#091A3D",
    border: " 1px solid #4B608D",
    position: "absolute",
    width: "100%",
    height: "100%",
    left: "10px",
    top: "-10px",
    zIndex: -1,
  },
};

const eventImageContainer = {
  minHeight: 300,
  position: "relative",
  width: { xs: "100%", md: "45%" },
  borderRight: "1px solid #4B608D",
  cursor: "pointer",
  "&:after": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    background:
      "linear-gradient(0deg, rgba(9, 26, 61, 0.55), rgba(9, 26, 61, 0.55))",
    transition: ".3s ease-in-out",
  },
  "&:hover:after": {
    opacity: 0.5,
  },
};

const Breakpoint = (props: { sx: any }) => {
  return (
    <Box
      component="div" sx={{ ...props.sx }}>
      <Box
        component="div"
        sx={{
          px: 2,
          textAlign: "center",
          mb: 8,
        }}
      >
        <Typography variant="h2" sx={{ mb: 1.6 }}>
          Find us at&nbsp;
          <Box component="span" sx={{ color: "#1CE7C2" }}>
            Breakpoint
          </Box>
        </Typography>
        <Typography>Singapore, September 20th-21st 2024</Typography>
        <Link
          component="a"
          target="_blank"
          href="https://maps.app.goo.gl/osFTzDXZ9QyJvo4K9"
          sx={{ zIndex: 2, ...btn }}
        >
          <Typography>Suntec City Convention Center</Typography>
        </Link>
        <Typography>Level 4 (Main Breakpoint Event Space)</Typography>
      </Box>

      <Box
        component="div"
        sx={{
          maxWidth: 1082,
          mx: "auto",
          mb: { xs: 8, md: 12 },
        }}
      >

        <Box
          component="div"
          sx={{
            background: "#091A3D",
            border: "1px solid #4B608D",
            display: "flex",
            flexWrap: "wrap",
            mt: 9,
          }}
        >
          <Box
            component="div"
            sx={eventImageContainer}
          >
            <Box component="div" sx={eventBox}>

            </Box>
            <StaticImage
              src="../../images/firedancer/bowers.png"
              alt="Kevin Bowers headshot"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
          <Box component="div" sx={eventInfo}>
            <Box
              component="div"
              sx={{
                position: "absolute",
                right: { xs: "-10px", md: "-20px" },
                top: "0",
                transform: "translateY(-50%)",
                "&:before": {
                  content: "''",
                  background: "#091A3D",
                  border: " 1px solid #4B608D",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  left: "10px",
                  top: "-10px",
                  zIndex: -1,
                },
              }}
            >
              <Box
                component="div"
                sx={{
                  background: "#091A3D",
                  border: "1px solid #4B608D",
                  display: "flex",
                  gap: { xs: "20px", md: "32px" },
                  p: "13px 28px",
                }}
              >
                <Box
                  component="span"
                  sx={{ display: "flex", alignItems: "center" }}
                >

                  <Typography sx={{ lineHeight: 1, ml: 1, fontWeight: 600 }}>
                    2:40-3:10pm
                  </Typography>
                </Box>
                <Box
                  component="span"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Calendar />
                  <Typography sx={{ lineHeight: 1, ml: 1, fontWeight: 600 }}>
                    September{" "}
                    <Box component="span" sx={{ color: "#1CE7C2" }}>
                      20th
                    </Box>
                    , 2024
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography variant="h3" sx={{ mb: 2.4 }}>
              Fast forward from Frankendancer to Firedancer
            </Typography>
            <Typography>
              Kevin Bowers
            </Typography>
          </Box>
        </Box>




        <Box
          component="div"
          sx={{
            background: "#091A3D",
            border: "1px solid #4B608D",
            display: "flex",
            flexWrap: "wrap",
            mt: 9,
          }}
        >
          <Box
            component="div"
            sx={eventImageContainer}
          >
            <Box component="div" sx={eventBox}>

            </Box>
            <StaticImage
              src="../../images/firedancer/anway_and_philip.png"
              alt="Headshots of Phillip and Anway"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
          <Box component="div" sx={eventInfo}>
            <Box
              component="div"
              sx={{
                position: "absolute",
                right: { xs: "-10px", md: "-20px" },
                top: "0",
                transform: "translateY(-50%)",
                "&:before": {
                  content: "''",
                  background: "#091A3D",
                  border: " 1px solid #4B608D",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  left: "10px",
                  top: "-10px",
                  zIndex: -1,
                },
              }}
            >
              <Box
                component="div"
                sx={{
                  background: "#091A3D",
                  border: "1px solid #4B608D",
                  display: "flex",
                  gap: { xs: "20px", md: "32px" },
                  p: "13px 28px",
                }}
              >
                <Box
                  component="span"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography sx={{ lineHeight: 1, ml: 1, fontWeight: 600 }}>
                    12:40-1:10pm
                  </Typography>
                </Box>
                <Box
                  component="span"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Calendar />
                  <Typography sx={{ lineHeight: 1, ml: 1, fontWeight: 600 }}>
                    September{" "}
                    <Box component="span" sx={{ color: "#1CE7C2" }}>
                      21st
                    </Box>
                    , 2024
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography variant="h3" sx={{ mb: 2.4 }}>
              Cranking Up Frankendancer
            </Typography>
            <Typography>
              Anway De & Phillip Taffet
            </Typography>
          </Box>
        </Box>



        <Box
          component="div"
          sx={{
            background: "#091A3D",
            border: "1px solid #4B608D",
            display: "flex",
            flexWrap: "wrap",
            mt: 9,
          }}
        >
          <Box
            component="div"
            sx={eventImageContainer}
          >
            <Box component="div" sx={eventBox}>

            </Box>
            <StaticImage
              src="../../images/firedancer/liam_aryaman.png"
              alt="Headshots of Liam and Aryaman"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
          <Box component="div" sx={eventInfo}>
            <Box
              component="div"
              sx={{
                position: "absolute",
                right: { xs: "-10px", md: "-20px" },
                top: "0",
                transform: "translateY(-50%)",
                "&:before": {
                  content: "''",
                  background: "#091A3D",
                  border: " 1px solid #4B608D",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  left: "10px",
                  top: "-10px",
                  zIndex: -1,
                },
              }}
            >
              <Box
                component="div"
                sx={{
                  background: "#091A3D",
                  border: "1px solid #4B608D",
                  display: "flex",
                  gap: { xs: "20px", md: "32px" },
                  p: "13px 28px",
                }}
              >
                <Box
                  component="span"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography sx={{ lineHeight: 1, ml: 1, fontWeight: 600 }}>
                    1:10-1:40pm
                  </Typography>
                </Box>
                <Box
                  component="span"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Calendar />
                  <Typography sx={{ lineHeight: 1, ml: 1, fontWeight: 600 }}>
                    September{" "}
                    <Box component="span" sx={{ color: "#1CE7C2" }}>
                      21st
                    </Box>
                    , 2024
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography variant="h3" sx={{ mb: 2.4 }}>
              Scenes From a Firedancer Validator
            </Typography>
            <Typography>
              Liam Heeger & Aryaman Jain
            </Typography>
          </Box>
        </Box>

      </Box>

    </Box>
  );
};

export default Breakpoint;
